import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { StaticImage } from 'gatsby-plugin-image';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { setGTMEvent } from '../utils/setGAEvent';
import { LocationLanguageContextType, LogInResponse, NovadeLiteUrlContextType } from '../typings';
import { globalColors } from '../styles/globalColorVariables';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import { Languages } from '../utils/getAllSupportedLanguages';
import { matchesMobile } from '../utils/mediaQuery';

interface StrapiLoginDialog {
  Description: string;
  EmailLabel: string;
  LoginLabel: string;
  PasswordLabel: string;
  ErrorMessage: string;
  locale: string;
}

interface LoginDialogQueryProps {
  allStrapiLoginDialog: {
    nodes: StrapiLoginDialog[];
  };
}

interface LoginDialogProps {
  open: boolean;
  onDialogClose: (value?: LogInResponse) => void;
}

interface LoginFormState {
  email: string;
  password: string;
  showPassword: boolean;
  loading: boolean;
  isEmailInvalid: boolean;
  error: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0.5rem 0.5rem 0 0'
    },
    dialogContainer: {
      padding: '0rem 4rem 4rem 4rem !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    dialogLogoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '2rem'
    },
    dialogFormContainer: {
      display: 'grid',
      gap: '1rem',
      marginBottom: '2rem'
    },
    linkButton: {
      textDecoration: 'none',
      color: globalColors.primaryColor
    },
    markdownText: {
      '& a': {
        textDecoration: 'none',
        color: globalColors.primaryColor
      },
      textAlign: 'center'
    }
  })
);

const NOVADE_LITE_LOGIN_URL_SEA = process.env.GATSBY_NOVADE_LITE_API_URL_SEA;
const NOVADE_LITE_LOGIN_URL_EU = process.env.GATSBY_NOVADE_LITE_API_URL_EU;
const NOVADE_LITE_LOGIN_URL_AUZ = process.env.GATSBY_NOVADE_LITE_API_URL_AUZ;

const LoginDialog: React.FC<LoginDialogProps> = ({ onDialogClose, open }) => (
  <StaticQuery
    query={graphql`
      query LoginDialogQuery {
        allStrapiLoginDialog {
          nodes {
            Description
            EmailLabel
            LoginLabel
            PasswordLabel
            ErrorMessage
            locale
          }
        }
      }
    `}
    render={(data: LoginDialogQueryProps) => {
      const classes = useStyles();

      const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
      const language: string = locationLanguage?.language ?? Languages.English;
      const loginDialogContent = data.allStrapiLoginDialog.nodes.find((x: any) => x.locale === language);

      const initialValues = {
        email: '',
        password: '',
        showPassword: false,
        isEmailInvalid: false,
        loading: false,
        error: ''
      };
      const [values, setValues] = React.useState<LoginFormState>(initialValues);

      const handleClose = () => {
        onDialogClose();
      };

      const handleChange = (prop: keyof LoginFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
      };
      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
      const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
      };
      const isEmailValid = (email: string) => {
        if (email === '') {
          setValues({ ...values, isEmailInvalid: false });
        }
        // eslint-disable-next-line max-len
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(email)) {
          setValues({ ...values, isEmailInvalid: false });
        } else {
          setValues({ ...values, isEmailInvalid: true });
        }
      };

      const novadeLiteAPI = React.useContext<NovadeLiteUrlContextType>(NovadeLiteUrlContext);

      async function login(url: string) {
        const response = await fetch(`${url}/login`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({
            email: values.email,
            password: values.password
          })
        });

        return response;
      }

      async function submitForm() {
        setValues({ ...values, loading: true });

        const [resSEA, resEU, resAUZ] = await Promise.all([
          login(NOVADE_LITE_LOGIN_URL_SEA!),
          login(NOVADE_LITE_LOGIN_URL_EU!),
          login(NOVADE_LITE_LOGIN_URL_AUZ!)
        ]);

        let logMessage = '';
        let endpoint = '';
        let response: any = {};

        if (resSEA.ok) {
          response = await resSEA.json();
          endpoint = NOVADE_LITE_LOGIN_URL_SEA!;
          logMessage = 'Login with Novade Lite SEA credentials';
        } else if (resEU.ok) {
          response = await resEU.json();
          endpoint = NOVADE_LITE_LOGIN_URL_EU!;
          logMessage = 'Login with Novade Lite EU credentials';
        } else if (resAUZ.ok) {
          response = await resAUZ.json();
          endpoint = NOVADE_LITE_LOGIN_URL_AUZ!;
          logMessage = 'Login with Novade Lite AUZ credentials';
        } else {
          setValues({ ...values, loading: false, error: loginDialogContent ? loginDialogContent.ErrorMessage : 'Error submitting form' });
          return;
        }

        const content: LogInResponse = {
          email: values.email,
          userID: response.userID,
          userDisplayName: response.userDisplayName,
          userPhotoID: response.photoID,
          databases: { [endpoint]: response.databases }
        };

        setValues(initialValues); // Clear email / password from login form
        novadeLiteAPI?.updateNovadeLiteUrl(endpoint);
        onDialogClose(content);
        setGTMEvent('login', 'login', logMessage);
      }
      const isMobile = matchesMobile();
      return (
        <Dialog fullScreen={isMobile} maxWidth='xs' fullWidth onClose={handleClose} aria-labelledby='simple-dialog-title' open={open}>
          {loginDialogContent ? (
            <>
              <div className={classes.dialogTitleContainer}>
                <IconButton aria-label='close' onClick={handleClose} className='close-icon-button'>
                  <CloseIcon />
                </IconButton>
              </div>
              <DialogContent className={classes.dialogContainer}>
                <div className={classes.dialogLogoContainer}>
                  <StaticImage
                    layout='constrained'
                    placeholder='tracedSVG'
                    src='../assets/LoginDialog/novade-lite-logo.svg'
                    alt='Novade Lite logo'
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <StaticImage
                    layout='constrained'
                    placeholder='tracedSVG'
                    src='../assets/LoginDialog/novade-lite-logo-text.svg'
                    alt='Novade Lite Text logo'
                  />
                </div>
                <div className={classes.dialogFormContainer}>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-email'>{loginDialogContent.EmailLabel}</InputLabel>
                    <OutlinedInput
                      id='outlined-email'
                      type='email'
                      value={values.email}
                      error={values.isEmailInvalid}
                      onBlur={() => isEmailValid(values.email)}
                      onChange={handleChange('email')}
                      labelWidth={40}
                    />
                  </FormControl>
                  <FormControl variant='outlined' fullWidth>
                    <InputLabel htmlFor='outlined-adornment-password'>{loginDialogContent.PasswordLabel}</InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      type={values.showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange('password')}
                      labelWidth={70}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </div>
                {values.error !== '' && (
                  <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                    <Typography variant='caption' color='error' align='center'>
                      {values.error}
                    </Typography>
                  </div>
                )}
                <Button
                  disabled={values.loading}
                  size='large'
                  fullWidth
                  variant='contained'
                  onClick={() => submitForm()}
                  color='secondary'
                  style={{ textTransform: 'uppercase', color: 'white', marginBottom: '1rem' }}
                >
                  {!values.loading ? (
                    loginDialogContent.LoginLabel
                  ) : (
                    <div>
                      <CircularProgress style={{ height: '1rem', width: '1rem', color: 'white' }} />
                    </div>
                  )}
                </Button>
                <Typography component='div'>
                  {/* eslint-disable-next-line react/no-children-prop */}
                  <ReactMarkdown children={loginDialogContent.Description} className={classes.markdownText} />
                </Typography>
              </DialogContent>
            </>
          ) : (
            <Typography variant='body1' />
          )}
        </Dialog>
      );
    }}
  />
);

export default LoginDialog;
