/* eslint-disable prettier/prettier */
import * as React from 'react';
import { AuthUserContextType, DatabasesMap, NovadeLiteUser } from '../typings';
import useWorkspacesWithRights from '../hooks/useWorkspacesWithRights';

export const AuthUserContext = React.createContext<AuthUserContextType>({
  user: undefined,
  updateUser: () => { },
  databasesMap: {},
  updateDatabasesMap: () => { },
  logout: () => { }
});

const AuthUserProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [user, setUser] = React.useState<NovadeLiteUser>();
  const [databasesMap, setDbMap] = React.useState<{ [url: string]: DatabasesMap }>({});

  const url = Object.keys(databasesMap)[0] || '';
  const token = url ? Object.values(databasesMap[url])[0]?.token : '';
  const { loading, error, workspacesMap } = useWorkspacesWithRights(url, token, databasesMap[url]);

  const updateDatabasesMap = (map: { [url: string]: DatabasesMap }) => {
    setDbMap(map);
  };

  const logout = () => {
    setUser(undefined);
    setDbMap({});
  };

  React.useEffect(() => {
    if (!loading && !error) {
      updateDatabasesMap({ [url]: workspacesMap });
    }
  }, [workspacesMap, user?.email]);

  return (
    <AuthUserContext.Provider
      value={{
        user,
        updateUser: setUser,
        databasesMap,
        updateDatabasesMap,
        logout
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
};

export default AuthUserProvider;
