import React from 'react';
import { ApolloProvider } from '@apollo/client';
import AuthUserProvider from "./src/contexts/AuthUserContext";
import LocationLanguageProvider from "./src/contexts/LocationLanguageContext";
import NovadeLiteUrlProvider from "./src/contexts/NovadeLiteUrlContext";
import LayoutRoot from "./src/components/LayoutRoot";
import client from './src/apolloClient';
import './src/styles/global.css';

export const wrapRootElement = ({ element }) => {
  return (
    <LocationLanguageProvider>
      <NovadeLiteUrlProvider>
        <ApolloProvider client={client}>
          <AuthUserProvider>
            <LayoutRoot>{element}</LayoutRoot>
          </AuthUserProvider>
        </ApolloProvider>
      </NovadeLiteUrlProvider>
    </LocationLanguageProvider>
  )
}
