import { CountryInfo, StrapiTemplateCategory } from '../typings';
// Staging site: Display all the languages for testing
// Production site: Display en, ja & zh-hant only for now
const backendURL = process.env.GATSBY_API_URL;
const stagingURL = 'https://app-sea-stage-template-library.azurewebsites.net';

export enum Languages {
  English = 'English',
  French = 'French',
  Deutsch = 'Deutsch',
  SimplifiedCH = 'Simplified Chinese',
  TraditionalCH = 'Traditional Chinese',
  Japanese = 'Japanese',
  Bahasa = 'Bahasa Indonesia',
  Spanish = 'Spanish'
}

export const defaultLocation: CountryInfo = {
  locationCode: '',
  locationName: ''
};

let stagingMode = false;
if (backendURL === stagingURL) {
  stagingMode = true;
}

export function getAllSupportedLanguages() {
  return [
    {
      locale: 'en',
      localeName: Languages.English,
      displayName: Languages.English,
      locationCodes: ['SG', 'UK']
    },
    {
      locale: 'zh-Hant',
      localeName: Languages.TraditionalCH,
      displayName: '繁體中文',
      locationCodes: ['HK', 'TW']
    },
    {
      locale: 'id',
      localeName: Languages.Bahasa,
      displayName: 'Bahasa Indonesia',
      locationCodes: ['ID']
    },
    {
      locale: 'fr',
      localeName: Languages.French,
      displayName: 'Français',
      locationCodes: ['FR']
    }
  ];
}

/**
 * @function getSupportedLanguage
 * @description Get the supported language based on location code. Default language 'English' if cannot find the supported language.
 * @param {CountryInfo} country - current location
 * @returns {string[]} supported language information
 */
export function getSupportedLanguage(country: CountryInfo) {
  // Get supported language based on user's country code
  const language = getAllSupportedLanguages().find((lang) => lang.locationCodes.includes(country.locationCode));
  return language || getAllSupportedLanguages()[0];
}

/**
 * @function getActionTextByLanguage
 * @description Gets the corresponding action text based on the template language and screen size.
 * @param templateLanguage - Template language
 * @param isMobile - true if it's mobile screen
 * @returns action text
 */
export function getActionTextByLanguage(templateLanguage: StrapiTemplateCategory['name'] | Languages.English, isMobile: boolean) {
  switch (templateLanguage) {
    case Languages.English:
      return isMobile ? 'Add New Row' : 'Edit Table';
    case Languages.Bahasa:
      return isMobile ? 'Tambah Barisan Baru' : 'Edit Tabel';
    case Languages.French:
      return isMobile ? 'Ajouter nouvelle ligne' : 'Editer la table';
    case Languages.TraditionalCH:
      return isMobile ? '添加新行' : '編輯表格';
    case Languages.Japanese:
      return isMobile ? '新しい行を追加' : 'テーブルの編集';
    default:
      return isMobile ? 'Add New Row' : 'Edit Table';
  }
}
