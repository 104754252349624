import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { StaticImage } from 'gatsby-plugin-image';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'gatsby';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import DashboardOutlined from '@material-ui/icons/DashboardOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import GTranslateOutlinedIcon from '@material-ui/icons/GTranslateOutlined';
import Select from '@material-ui/core/Select';
import { useStrapiHeader } from '../hooks/useStrapiHeader';
import { getAllSupportedLanguages, Languages } from '../utils/getAllSupportedLanguages';
import { setGTMEvent } from '../utils/setGAEvent';
import { AuthUserContextType, LogInResponse, LocationLanguageContextType, NovadeLiteUrlContextType, StrapiHeader } from '../typings';
import { AuthUserContext } from '../contexts/AuthUserContext';
import LoginDialog from './LoginDialog';
import { LocationLanguageContext } from '../contexts/LocationLanguageContext';
import { NovadeLiteUrlContext } from '../contexts/NovadeLiteUrlContext';
import { enterToSearch, getInitials } from '../utils/common';

interface HeaderMobileProps {
  isComingSoonPage: boolean;
}

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  onClickFunction: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    headerMobileContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    initialsCircle: {
      padding: '0.5rem',
      background: 'rgb(230,230,230)',
      borderRadius: '50%',
      width: '1.5rem',
      height: '1.5rem',
      letterSpacing: '-1px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '1rem'
    }
  })
);

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, onClickFunction } = props;
  const renderLink = React.useMemo(
    () => React.forwardRef<any, Omit<any, 'to'>>((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClickFunction}>
        {icon ? <>{icon}</> : null}
        &nbsp;&nbsp;
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ isComingSoonPage }) => {
  const classes = useStyles();
  const [searchFieldValue, setSearchFieldValue] = React.useState('');
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [isSearchbarOpen, setIsSearchbarOpen] = React.useState(false);

  const authUser = React.useContext<AuthUserContextType>(AuthUserContext);
  const novadeLiteAPI = React.useContext<NovadeLiteUrlContextType>(NovadeLiteUrlContext);
  const locationLanguage = React.useContext<LocationLanguageContextType | null>(LocationLanguageContext);
  const language: string = locationLanguage?.language ?? Languages.English;
  const databasesMap = novadeLiteAPI ? authUser?.databasesMap[novadeLiteAPI.novadeLiteUrl] || {} : {};
  const { token } = Object.values(databasesMap)[0] ?? '';

  const strapiHeaders = useStrapiHeader();
  const headerContent = strapiHeaders.find((x) => x.locale === language);
  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    locationLanguage?.updateLanguage(event.target.value as string);
    setIsOpenDrawer(false);
  };

  const login = () => {
    setIsOpenDialog(true);
  };
  async function submitLogoutRequest() {
    const NOVADE_LITE_LOGOUT_URL = `${novadeLiteAPI?.novadeLiteUrl}/logout`;
    const response = await fetch(NOVADE_LITE_LOGOUT_URL, {
      method: 'POST',
      headers: token ? { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${token}` } : undefined,
      credentials: 'include',
      body: ''
    });
    if (!response.ok) {
      console.log('Error logging out');
    } else {
      setGTMEvent('logout', 'logout', 'Logout from Novade Template App');
      novadeLiteAPI?.updateNovadeLiteUrl(process.env.GATSBY_NOVADE_LITE_LOGIN_URL_SEA!);
    }
  }
  const logout = () => {
    authUser?.logout();
    novadeLiteAPI?.updateNovadeLiteUrl(process.env.GATSBY_NOVADE_LITE_LOGIN_URL_SEA!);
    submitLogoutRequest();
    localStorage.clear();
    setIsOpenDialog(false);
    setIsOpenDrawer(false);
  };

  const search = (event: React.KeyboardEvent) => {
    enterToSearch(event, searchFieldValue);
  };

  const toggleDrawer = () => {
    setIsOpenDrawer((isOpen) => !isOpen);
  };
  const toggleSearchbar = () => {
    setIsSearchbarOpen((isOpen) => !isOpen);
  };
  const handleDialogClose = (value: LogInResponse | undefined) => {
    if (value) {
      authUser?.updateUser({
        email: value.email,
        displayName: value.userDisplayName
      });
      authUser?.updateDatabasesMap(value.databases);
    }
    setIsOpenDialog(false);
    setIsOpenDrawer(false);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFieldValue(event.target.value);
  };
  const clearSearchbar = () => {
    setSearchFieldValue('');
  };

  return (
    <AppBar position='sticky' color='transparent'>
      {headerContent ? (
        <>
          {isSearchbarOpen ? (
            <Toolbar style={{ backgroundColor: 'white' }}>
              <div className={classes.headerMobileContainer}>
                <IconButton aria-label='search' onClick={toggleSearchbar}>
                  <ArrowBackIcon />
                </IconButton>
                <InputBase
                  onChange={handleSearchChange}
                  fullWidth
                  onKeyDown={search}
                  value={searchFieldValue}
                  placeholder={headerContent.search}
                  inputProps={{ 'aria-label': 'Search for templates' }}
                />
                {searchFieldValue.length > 0 ? (
                  <IconButton aria-label='clear' onClick={clearSearchbar}>
                    <ClearIcon />
                  </IconButton>
                ) : null}
              </div>
            </Toolbar>
          ) : (
            <Toolbar style={{ backgroundColor: 'white' }}>
              <div className={classes.headerMobileContainer}>
                {!isComingSoonPage ? (
                  <IconButton aria-label='menu' onClick={toggleDrawer}>
                    <MenuIcon />
                  </IconButton>
                ) : null}
                <div />
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <StaticImage
                    width={30}
                    layout='constrained'
                    placeholder='tracedSVG'
                    src='../assets/Header/novade-template-logo-square.svg'
                    alt='Novade logo'
                  />
                </div>
                <div />
                {!isComingSoonPage ? (
                  <IconButton aria-label='search' onClick={toggleSearchbar}>
                    <SearchIcon />
                  </IconButton>
                ) : null}
              </div>
              <Drawer anchor='left' open={isOpenDrawer} onClose={toggleDrawer}>
                <List component='nav' aria-label='mobile menu' style={{ minWidth: '260px' }}>
                  {authUser?.user ? (
                    <div>
                      <ListItem style={{ marginBottom: '0.5rem' }}>
                        <div className={classes.initialsCircle}>
                          {authUser.user.displayName ? (
                            getInitials(authUser.user.displayName)
                          ) : (
                            <StaticImage
                              width={48}
                              layout='constrained'
                              placeholder='tracedSVG'
                              src='../assets/Header/camera_black.svg'
                              alt='User'
                            />
                          )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography variant='body2'>{authUser.user.displayName}</Typography>
                          <Typography variant='body2'>{authUser.user.email}</Typography>
                        </div>
                      </ListItem>
                      <Divider />
                    </div>
                  ) : null}
                  <ListItemLink icon={<HomeOutlinedIcon />} to='/' primary={headerContent.home} onClickFunction={toggleDrawer} />
                  <ListItemLink
                    icon={<PageviewOutlinedIcon />}
                    to='/browse'
                    primary={headerContent.templates}
                    onClickFunction={toggleDrawer}
                  />
                  <ListItemLink
                    icon={<DashboardOutlined />}
                    to='/dashboards'
                    primary={headerContent.dashboards}
                    onClickFunction={toggleDrawer}
                  />
                  <ListItem>
                    <Select
                      fullWidth
                      disableUnderline
                      value={language}
                      onChange={handleLanguageChange}
                      startAdornment={
                        <InputAdornment position='start'>
                          <GTranslateOutlinedIcon />
                        </InputAdornment>
                      }
                    >
                      {getAllSupportedLanguages().map((eachLang) => (
                        <MenuItem key={eachLang.locale} value={eachLang.locale}>
                          {eachLang.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                  </ListItem>
                </List>
                <List component='nav' aria-label='mobile menu' style={{ minWidth: '260px', position: 'absolute', bottom: '0' }}>
                  {authUser?.user ? (
                    <ListItem button onClick={logout}>
                      <ExitToAppOutlinedIcon />
                      &nbsp;&nbsp;
                      <ListItemText primary={headerContent.logout} />
                    </ListItem>
                  ) : (
                    <ListItem button onClick={login}>
                      <ExitToAppOutlinedIcon />
                      &nbsp;&nbsp;
                      <ListItemText primary={headerContent.login} />
                    </ListItem>
                  )}
                  <LoginDialog open={isOpenDialog} onDialogClose={handleDialogClose} />
                </List>
              </Drawer>
            </Toolbar>
          )}
        </>
      ) : null}
    </AppBar>
  );
};

export default HeaderMobile;
