import { useStaticQuery, graphql } from 'gatsby';
import { HeaderQueryProps } from 'typings';

const headerQuery = graphql`
  query MyQuery {
    allStrapiHeader {
      nodes {
        login
        logoLabel
        logout
        signUp
        signUpUrl
        templates
        dashboards
        home
        locale
      }
    }
  }
`;

export const useStrapiHeader = () => {
  const { allStrapiHeader } = useStaticQuery<HeaderQueryProps>(headerQuery);
  return allStrapiHeader ? allStrapiHeader.nodes : [];
};
