import * as React from 'react';
import { LocationLanguageContextType, CountryInfo } from '../typings';
import { getAllSupportedLanguages, defaultLocation } from '../utils/getAllSupportedLanguages';
import { setGTMEvent } from '../utils/setGAEvent';

export const LocationLanguageContext = React.createContext<LocationLanguageContextType | null>(null);

const LocationLanguageProvider: React.FC<React.ReactNode> = ({ children }) => {
  const browserLang = React.useMemo(() => {
    if (typeof navigator === 'object' && navigator && navigator.language) {
      const lang = navigator.language;
      if (lang.startsWith('zh')) return lang !== 'zh-CN' ? 'zh-Hant' : 'zh-Hans';
      return lang.split('-')[0];
    }
    return 'en';
  }, []);

  const isSupportedLang = getAllSupportedLanguages().find((x) => x.locale === browserLang) !== undefined;
  const defaultLang = isSupportedLang ? browserLang : 'en';

  const [country, setCountry] = React.useState<CountryInfo>(defaultLocation);
  const [language, setLanguage] = React.useState<string>(defaultLang);

  // Flag to handle cleanup
  const isMountedRef = React.useRef(true);

  React.useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const updateCountry = (value: CountryInfo) => {
    setCountry(value);
    if (isMountedRef.current) {
      setGTMEvent('Country', 'select_content', value.locationName);
    }
  };

  const updateLanguage = (value: string) => {
    setLanguage(value);
    if (isMountedRef.current) {
      setGTMEvent('Language', 'select_content', value);
    }
  };

  return (
    <LocationLanguageContext.Provider
      value={{
        country,
        updateCountry,
        language,
        updateLanguage
      }}
    >
      {children}
    </LocationLanguageContext.Provider>
  );
};

export default LocationLanguageProvider;
