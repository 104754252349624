/* eslint-disable prettier/prettier */
import * as React from 'react';
import { NovadeLiteUrlContextType } from '../typings';

const defaultUrl = process.env.GATSBY_NOVADE_LITE_API_URL_SEA as string;

export const NovadeLiteUrlContext = React.createContext<NovadeLiteUrlContextType>({
  novadeLiteUrl: defaultUrl,
  updateNovadeLiteUrl: () => { }
});

const NovadeLiteUrlProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [novadeLiteUrl, setNovadeLiteUrl] = React.useState<string>(defaultUrl!);

  const updateNovadeLiteUrl = (value: string) => {
    setNovadeLiteUrl(value ?? defaultUrl);
  };

  return <NovadeLiteUrlContext.Provider value={{ novadeLiteUrl, updateNovadeLiteUrl }}>{children}</NovadeLiteUrlContext.Provider>;
};

export default NovadeLiteUrlProvider;
